import React, { useContext } from 'react';

import { StoreContext } from '../context';
import { Cart, Loader } from '../components';

import { AppBar, Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}

    >
      {value === index && <StyledBox p={3}>{children}</StyledBox>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledBox = withStyles((theme) => ({
  root: {
    padding: 15,
  },
}))(Box);

const StyledTab = withStyles((theme) => ({
  wrapper: {
    alignItems: 'flex-start',
  },
}))(Tab);

const CartTab = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ minWidth: 475 }}>
      <TabPanel value={0} index={0}>
        <Cart />
      </TabPanel>
    </div>
  );
};

const CartTabs = () => {
  const classes = useStyles();
  const cartInfo = useContext(StoreContext).cartInfo;
  const quoteLoading = useContext(StoreContext).quoteLoading;

  return (
    <div className={classes.root}>
      <AppBar
        color={cartInfo?.orderId ? 'secondary' : 'primary'}
        position='static'
        style={{ minWidth: 475 }}
      >
        <Tabs value={0} variant='fullWidth' style={{ minWidth: 475 }}>
          <StyledTab
            label={`${
              cartInfo?.orderId
                ? cartInfo.status + ' Order: ' + cartInfo.orderName
                : 'Cart'
            }`}
          />
        </Tabs>
      </AppBar>
      {quoteLoading ? <Loader /> : <CartTab value={0} />}
    </div>
  );
};

export { CartTabs };
